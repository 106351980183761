import React from 'react';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonButton,
} from '@ionic/react';
import AlertPreferences from '../components/AlertPreferences';
import AlertListPopover from '../components/AlertListPopover';
import AlertsList from '../components/AlertsList';
import Card from 'react-bootstrap/Card';

const Home = () => {
  return (
    <IonPage id="Projects">
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Home</IonTitle>
          <AlertListPopover />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            {/* Main content */}
            <IonCol sizeXl="8" sizeLg="8" sizeMd="7" sizeSm="12" sizeXs="12">
              <IonCard mode="ios">
                <IonCardHeader>
                  <IonCardTitle className="project-list">Anna Maria Island Capital Improvement Projects</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <div className="project-text">
                    <p>
                      Manateee County water and wastewater systems are vital to the quality of life enjoyed by
                      residents, businesses, and visitors to Anna Maria Island. However, much of the existing water and
                      wastewater infrastructure on the island was constructed in the 1970’s and is reaching the end of
                      its service life. As part of the Manatee County Capital Improvement Plan (CIP), the Public Works
                      Department is replacing these aging drinking water and wastewater pipelines throughout the island
                      in order to improve reliability and increase capacity. The County CIP also includes other island
                      infrastructure projects such as erosion prevention measures and improvements to public parking
                      facilities that will protect and enhance access to the island’s beautiful beaches.
                    </p>
                    <p>
                      The pipelines being replaced on the island include force mains, water mains and gravity drainage
                      pipes. Force mains are pressurized pipelines that convey raw wastewater to a treatment facility.
                      Water mains are pressurized pipelines that supply drinking water to homes and businesses. Gravity
                      drainage pipes collect stormwater from rainfall events and use gravity to transfer the water to
                      ponds and swales for storage and treatment.
                    </p>
                    <p>
                      Construction activities associated with the CIP projects will take place within the Manatee
                      County, City of Anna Maria, and City of Bradenton Beach established property easements and
                      rights-of-way. Additionally, access to homes, businesses, and beaches will be maintained while the
                      projects are underway and any required water service interruptions will be relatively brief.
                      Sidewalks, driveways, mailboxes, and landscaping impacted by construction will also be restored
                      upon completion.
                    </p>
                    <p>
                      Work to install new pipelines and restore local roads and sidewalks will generally take place on
                      weekdays from 7 a.m. to 7 p.m. and may require temporary lane closures, detours, and flagging
                      operations. Residents and visitors are asked to exercise caution when traveling through these
                      construction areas. Pedestrians and bicyclists should use designated crossing locations and avoid
                      crossing through active construction areas.
                    </p>
                  </div>
                  <IonGrid className="more-info-grid ">
                    <IonRow className="ion-align-items-center ion-justify-content-center">
                      <IonCol sizeXl="3" sizeLg="3" sizeMd="3" sizeXs="10">
                        <div className="manatee-logo">
                          <a href="https://mymanatee.org/" target="_blank" rel="noopener noreferrer">
                            <img src="/assets/Manatee-Logo.png" alt="Manatee County Logo" />
                          </a>
                        </div>
                      </IonCol>
                      <IonCol sizeXl="9" sizeLg="9" sizeMd="9" sizeXs="12" className="more-info-box">
                        <p className="ion-no-margin">
                          For more information on specific Manatee County Public Works projects on Anna Maria Island,
                          please visit the Projects page.
                        </p>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCardContent>
              </IonCard>
            </IonCol>
            {/* Sidebar content - Alert List and Latest News Article */}
            <IonCol sizeXl="4" sizeLg="4" sizeMd="5" sizeSm="12" sizeXs="12">
              <IonCard mode="ios" className="project-list   ion-hide-sm-down">
                <IonCardHeader>
                  <IonCardTitle className="project-list">Alerts</IonCardTitle>
                  <AlertPreferences />
                </IonCardHeader>
                <IonCardContent>
                  <AlertsList />
                </IonCardContent>
              </IonCard>
              <IonCard mode="ios" className="gallery">
                <IonCardHeader>
                  <IonCardTitle className="project-list">In The News</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <Card className="news">
                    <Card.Img
                      variant="top"
                      src="/assets/coquina-aerial.jpg"
                      alt="Aerial photo of anna maria island beaches"
                    />
                    <Card.Body>
                      <Card.Title>Anna Maria Island-wide beach renourishment project to begin in July</Card.Title>
                      <Card.Text>
                        AMI beaches will be restocked with fresh, white sand from Holmes Beach to Coquina Beach
                      </Card.Text>
                      <IonButton
                        href="https://www.sarasotamagazine.com/news-and-profiles/2020/06/anna-maria-island-wide-beach-renourishment-project-to-begin-in-july"
                        target="_blank"
                        color="secondary"
                        fill="solid"
                        rel="noopener noreferrer"
                      >
                        Read More
                      </IonButton>
                    </Card.Body>
                  </Card>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Home;
