import React from "react";
import styles from "../theme/thankYou.module.css";

const ThankYou = () => {
  return (
    <>
      <h1 className={styles.thanks}>Thank you!</h1>
      <h3 className={styles.message}>
        Your form has been submitted, we appreciate your feedback and a
        representative will be in contact shortly.
      </h3>
    </>
  );
};

export default ThankYou;
