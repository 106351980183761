import { combineReducers } from "./combineReducers";
import { appReducer } from "./app.reducer";

export const initialState = {
  data: {
    projects: [],
    projectAttachments: [],
    alerts: [],
    alertAttachments: [],
    faqs: [],
    loading: true,
    menuEnabled: true,
    networkStatus: false,
  },
};

export const reducers = combineReducers({
  data: appReducer,
});
