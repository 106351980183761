import React from 'react';
import { useLocation } from 'react-router-dom';
import { IonIcon, IonItem, IonLabel, IonCardSubtitle } from '@ionic/react';
import { warning, create, checkmarkCircle } from 'ionicons/icons';
import { connect } from '../data/connect';

const ProjectList = ({ projects }) => {
  const location = useLocation();

  let projectsInDesign = [];
  let projectsInConstruction = [];
  let projectsCompleted = [];

  [projects].flat().forEach((project) => {
    if (project.attributes.status === 'inDesign') {
      projectsInDesign.push(project);
    } else if (project.attributes.status === 'inConstruction') {
      projectsInConstruction.push(project);
    } else if (project.attributes.status === 'completed') {
      projectsCompleted.push(project);
    }
  });

  return (
    <>
      {projectsInDesign.length > 0 && (
        <IonCardSubtitle style={{ marginBottom: '10px' }} color="indesign">
          In Design
        </IonCardSubtitle>
      )}
      {projectsInDesign.map((project) => (
        <IonItem
          lines="none"
          key={project.attributes.slug}
          detail={true}
          routerLink={`/projects/${project.attributes.slug}`}
          routerDirection="none"
          className={
            location.pathname === `/projects/${project.attributes.slug}`
              ? `selected-${project.attributes.status}`
              : `${project.attributes.status}`
          }
        >
          <IonIcon
            slot="start"
            icon={
              project.attributes.status === 'inConstruction'
                ? warning
                : project.attributes.status === 'inDesign'
                ? create
                : checkmarkCircle
            }
            className="ion-no-margin"
            color={
              project.attributes.status === 'inConstruction'
                ? `inconstruction`
                : project.attributes.status === 'inDesign'
                ? `indesign`
                : `completed`
            }
          />
          <IonLabel style={{ fontSize: '16px' }}>{project.attributes.title}</IonLabel>
        </IonItem>
      ))}

      {projectsInConstruction.length > 0 && (
        <IonCardSubtitle style={{ marginTop: '15px', marginBottom: '10px' }} color="inconstruction">
          In Construction
        </IonCardSubtitle>
      )}
      {projectsInConstruction.map((project) => (
        <IonItem
          lines="none"
          key={project.attributes.slug}
          detail={true}
          routerLink={`/projects/${project.attributes.slug}`}
          routerDirection="none"
          className={
            location.pathname === `/projects/${project.attributes.slug}`
              ? `selected-${project.attributes.status}`
              : `${project.attributes.status}`
          }
        >
          <IonIcon
            slot="start"
            icon={
              project.attributes.status === 'inConstruction'
                ? warning
                : project.attributes.status === 'inDesign'
                ? create
                : checkmarkCircle
            }
            className="ion-no-margin"
            color={
              project.attributes.status === 'inConstruction'
                ? `inconstruction`
                : project.attributes.status === 'inDesign'
                ? `indesign`
                : `completed`
            }
          />
          <IonLabel style={{ fontSize: '16px' }}>{project.attributes.title}</IonLabel>
        </IonItem>
      ))}
      {projectsCompleted.length > 0 && (
        <IonCardSubtitle style={{ marginTop: '15px', marginBottom: '10px' }} color="completed">
          Completed
        </IonCardSubtitle>
      )}
      {projectsCompleted.map((project) => (
        <IonItem
          lines="none"
          key={project.attributes.slug}
          detail={true}
          routerLink={`/projects/${project.attributes.slug}`}
          routerDirection="none"
          className={
            location.pathname === `/projects/${project.attributes.slug}`
              ? `selected-${project.attributes.status}`
              : `${project.attributes.status}`
          }
        >
          <IonIcon
            slot="start"
            icon={
              project.attributes.status === 'inConstruction'
                ? warning
                : project.attributes.status === 'inDesign'
                ? create
                : checkmarkCircle
            }
            className="ion-no-margin"
            color={
              project.attributes.status === 'inConstruction'
                ? `inconstruction`
                : project.attributes.status === 'inDesign'
                ? `indesign`
                : `completed`
            }
          />
          <IonLabel style={{ fontSize: '16px' }}>{project.attributes.title}</IonLabel>
        </IonItem>
      ))}
    </>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    projects: state.data.projects,
  }),
  component: React.memo(ProjectList),
});
