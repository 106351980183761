import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {
  IonItem,
  IonLabel,
  IonRow,
  IonCol,
  IonButton,
  IonInput,
  IonTextarea,
  IonText,
  IonList,
  IonCardContent,
} from '@ionic/react';
import useForm from '../hooks/AccessibilityForm';
import validate from './validation/validateAccessibility';
import ThankYou from './ThankYou';

const AccessibilityModal = (props, { submitForm }) => {
  const { handleChange, handleBlur, handleSubmit, values, errors, isSubmitting } = useForm(submitForm, validate);

  return (
    <Modal
      {...props}
      size="lg"
      dialogClassName="modal-component"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Accessibility</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <IonCardContent className="ion-no-padding">
          <p>
            The developers of this website strive to ensure that its services are accessible to people with
            disabilities, with the strong belief that every person has the right to live with dignity, equality, comfort
            and independence both in life, and on the internet.
          </p>
          <br />
          <p>
            If you are unable to use any aspect of this website, PDF files, or other attachments, we can provide you
            with prompt assistance. Please let us know by filling out the form below or by emailing{' '}
            <a href="mailto:ada-support@valerin-group.com">ada-support@valerin-group.com</a>
          </p>
        </IonCardContent>
        {!isSubmitting ? (
          <form onSubmit={handleSubmit} id="contactForm">
            <IonList className="project-list">
              <IonRow>
                <IonCol>
                  <IonItem mode="md">
                    <IonLabel position="floating">First Name</IonLabel>
                    <IonInput
                      type="text"
                      name="firstName"
                      value={values.firstName}
                      onIonChange={handleChange}
                      onIonBlur={handleBlur}
                    ></IonInput>
                  </IonItem>
                  {errors.firstName && (
                    <p
                      style={{
                        fontSize: '13px',
                        marginLeft: '16px',
                        color: 'var(--ion-color-danger)',
                      }}
                    >
                      {errors.firstName}
                    </p>
                  )}
                </IonCol>
                <IonCol>
                  <IonItem mode="md">
                    <IonLabel position="floating">Last Name</IonLabel>
                    <IonInput
                      type="text"
                      name="lastName"
                      value={values.lastName}
                      onIonChange={handleChange}
                      onIonBlur={handleBlur}
                    ></IonInput>
                  </IonItem>
                  {errors.lastName && (
                    <p
                      style={{
                        fontSize: '13px',
                        marginLeft: '16px',
                        color: 'var(--ion-color-danger)',
                      }}
                    >
                      {errors.lastName}
                    </p>
                  )}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonItem mode="md">
                    <IonLabel position="floating">Phone Number</IonLabel>
                    <IonInput
                      name="phoneNumber"
                      type="tel"
                      value={values.phoneNumber}
                      onIonChange={handleChange}
                      onIonBlur={handleBlur}
                    ></IonInput>
                  </IonItem>
                  {errors.phoneNumber && (
                    <p
                      style={{
                        fontSize: '13px',
                        marginLeft: '16px',
                        color: 'var(--ion-color-danger)',
                      }}
                    >
                      {errors.phoneNumber}
                    </p>
                  )}
                </IonCol>
                <IonCol>
                  <IonItem mode="md">
                    <IonLabel position="floating">Email Address</IonLabel>
                    <IonInput
                      name="email"
                      type="email"
                      value={values.email}
                      onIonChange={handleChange}
                      onIonBlur={handleBlur}
                    ></IonInput>
                  </IonItem>
                  {errors.email && (
                    <p
                      style={{
                        fontSize: '13px',
                        marginLeft: '16px',
                        color: 'var(--ion-color-danger)',
                      }}
                    >
                      {errors.email}
                    </p>
                  )}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonItem mode="md">
                    <IonLabel position="floating">Message</IonLabel>
                    <IonTextarea
                      type="text"
                      name="message"
                      value={values.message}
                      onIonChange={handleChange}
                      onIonBlur={handleBlur}
                    ></IonTextarea>
                  </IonItem>
                  {errors.message && (
                    <p
                      style={{
                        fontSize: '13px',
                        marginLeft: '16px',
                        color: 'var(--ion-color-danger)',
                      }}
                    >
                      {errors.message}
                    </p>
                  )}
                  <IonButton
                    mode="ios"
                    fill="solid"
                    size="default"
                    color="secondary"
                    className="ion-margin-top ion-margin-bottom button-text-white"
                    type="submit"
                  >
                    Send Message
                  </IonButton>
                  <br />
                  <IonText color="medium">
                    <p>
                      Under Florida law, e-mail addresses are public records. If you do not want your e-mail address
                      released in response to a public-records request, do not send electronic mail to this entity.
                      Instead, contact this office by phone or in writing.
                    </p>
                  </IonText>
                </IonCol>
              </IonRow>
            </IonList>
          </form>
        ) : (
          <ThankYou />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AccessibilityModal;
