import React from "react";
import Modal from "react-bootstrap/Modal";

const PrivacyModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      dialogClassName="modal-component"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Privacy Policy
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Thank you for visiting the Anna Maria Island Improvement Projects
          website and reviewing our privacy policy.
        </p>

        <h2>
          <strong>Electronic Mail (E-mail)</strong>
        </h2>
        <p>
          Under Florida law, e-mail addresses are public records. If you do not
          want your e-mail address released in response to a public-records
          request, please do not send e-mail to this entity. Instead, contact us
          by phone or in writing.
        </p>

        <h2>
          <strong>Information Collected and Stored Automatically</strong>
        </h2>
        <p>
          Anna Maria Island Improvement Projects will collect no personal
          information about you when you visit our website unless you choose to
          provide that information to us. However, as you browse through the
          website, read pages or download information, we will gather and store
          certain information about your visit automatically. This information
          does not identify you personally. We automatically collect and store
          only the following information about your visit when you access our
          website:
          <ul>
            <li>
              The internet domain (for example, "xcompany.com") and IP address
              (an IP address is a number that is automatically assigned to your
              computer whenever you are on the internet);
            </li>
            <li>
              The type of browser and operating system used to access our
              website;
            </li>
            <li>The date and time you access our website;</li>
            <li>The pages you visit; and</li>
            <li>
              If you linked to the Anna Maria Island Improvement Projects
              website from another website, the IP address of that website.
            </li>
          </ul>
          We use this information to help us make our website more useful to
          visitors, to learn about the number of visitors to our site and the
          types of technology our visitors use. We do not track or record
          information about individuals and their visits.
        </p>

        <h2>
          <strong>If You Send Us Personal Information</strong>
        </h2>
        <p>
          If you choose to provide us with personal information - as in an
          e-mail to Anna Maria Island Improvement Projects staff or by
          completing a form with your personal information and submitting it to
          us through our website - we use that information to respond to your
          message and provide you the information you have requested. We treat
          e-mails the same way we treat letters sent to the Anna Maria Island
          Improvement Projects offices. We are required to maintain many
          documents for historical purposes, but we do not collect personal
          information for any purpose other than to respond to you. However,
          information we receive may be considered public information which is
          subject to disclosure under Florida law. Except for information
          disclosure required by Florida law, any personal information you
          provide would only be shared with another government agency, if your
          inquiry relates to that agency. Moreover, we do not create individual
          profiles with the information you provide or provide such information
          to any private organizations. Anna Maria Island Improvement Projects
          does not collect information for commercial marketing.
        </p>

        <h2>
          <strong>Protection of Minors</strong>
        </h2>
        <p>
          It is important that children receive adult guidance prior to
          providing personal information online. We specifically ask that
          children to get the permission of a responsible adult or guardian
          before providing any information online - at our site or any other
          site - and recommend a responsible adult always be involved in online
          activities and actions. Should a child receive permission to provide
          personal information through the Anna Maria Island Improvement
          Projects website, that information will only be used to enable us to
          respond to the writer. We will also automatically collect the
          information as described above in Information Collected and Stored
          Automatically.
        </p>

        <h2>
          <strong>Cookie Use</strong>
        </h2>
        <p>
          A cookie is a tiny piece of text that, with your permission, is placed
          on your computer hard drive. If your browser is set to notify you
          before accepting a cookie, you will receive a request for a cookie to
          be stored on your computer. This cookie, by itself, will only advise
          us that a previous visitor has returned. It does not provide private
          information about you. The acceptance of a cookie is optional and will
          not prevent or preclude you from accessing information on the Anna
          Maria Island Capital Improvement Projects website.
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default PrivacyModal;
