/* eslint-disable default-case */

export const appReducer = (state, action) => {
  switch (action.type) {
    case 'set-app-loading': {
      return { ...state, loading: action.isLoading };
    }
    case 'set-network-status': {
      return { ...state, networkStatus: action.networkStatus };
    }
    case 'set-app-data': {
      return { ...state, ...action.data };
    }
    case 'set-menu-enabled': {
      return { ...state, menuEnabled: action.menuEnabled };
    }
  }
};
