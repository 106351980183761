import React, { useRef } from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardHeader,
  IonList,
  IonItem,
  IonLabel,
  IonText,
} from "@ionic/react";
import AlertListPopover from "../components/AlertListPopover";
import DocumentList from "../components/DocumentList";
import { connect } from "../data/connect";

const Resources = ({ faqs }) => {
  const faqTitle = useRef(null);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>AMI Projects</IonTitle>
          <AlertListPopover />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol
              sizeXl="8.5"
              sizeLg="8.5"
              sizeMd="7"
              sizeSm="12"
              sizeXs="12"
            >
              <IonCard mode="ios">
                <IonCardHeader>
                  <IonCardTitle className="project-list">
                    <span
                      className="focused-title"
                      tabIndex="-1"
                      ref={faqTitle}
                    >
                      Frequently Asked Questions
                    </span>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonList lines="none">
                    {faqs.map((faq) => (
                      <IonItem key={faq.id} className="faq-item">
                        <IonLabel className="ion-text-wrap">
                          <IonText color="secondary">
                            <p
                              style={{
                                fontSize: "18px",
                                fontWeight: "600",
                                marginBottom: "5px",
                              }}
                            >
                              {" "}
                              {faq.question}
                            </p>
                          </IonText>
                          <IonText>
                            <p>{faq.answer}</p>
                          </IonText>
                        </IonLabel>
                      </IonItem>
                    ))}
                  </IonList>
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol sizeXl="3.5" sizeLg="3.5" sizeMd="5" sizeXs="12">
              <IonCard mode="ios">
                <IonCardHeader>
                  <IonCardTitle className="project-list">
                    Documents
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <DocumentList />
                </IonCardContent>
              </IonCard>
              <IonCard mode="ios" className="resource-links">
                <IonCardHeader>
                  <IonCardTitle className="project-list">
                    Manatee County
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonButton
                    fill="solid"
                    target="_blank"
                    href="https://mymanatee.org/gisportal/apps/opsdashboard/index.html#/475ee26e749047c29181d5f5f597c6dd"
                    routerDirection="none"
                    className="button-text-white"
                    color="secondary"
                  >
                    Capital Project Dashboard
                  </IonButton>
                  <br />
                  <IonButton
                    fill="solid"
                    target="_blank"
                    href="https://www.mymanatee.org/"
                    routerDirection="none"
                    className="button-text-white"
                    color="secondary"
                  >
                    MyManatee.org
                  </IonButton>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    faqs: state.data.faqs,
  }),
  component: React.memo(Resources),
});
