import React, { useState } from "react";
import { IonIcon, IonList, IonPopover, IonButton } from "@ionic/react";
import { menu } from "ionicons/icons";

import ProjectList from "./ProjectList";

const ProjectListPopover = () => {
  const [popoverState, setShowPopover] = useState({ showPopover: false });
  return (
    <>
      <IonPopover
        mode="ios"
        cssClass="project-list-popover"
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() => setShowPopover({ showPopover: false })}
      >
        <IonList className="project-list">
          <ProjectList />
        </IonList>
      </IonPopover>
      <IonButton
        mode="ios"
        color="secondary"
        shape="round"
        strong
        size="default"
        onClick={(e) => {
          e.persist();
          setShowPopover({ showPopover: true, event: e });
        }}
        className="ion-hide-lg-up ion-margin-bottom"
      >
        <IonIcon slot="end" icon={menu} />
        Select A Project
      </IonButton>
    </>
  );
};

export default ProjectListPopover;
