import { getAppData } from './dataFetch';

export const loadAppData = () => async (dispatch) => {
  const data = await getAppData();
  dispatch(setData(data));
  dispatch(setLoading(false));
  console.log(data);
};
// export const loadAppData = () => async (dispatch) => {
// dispatch(setLoading(true));
// await getAppData()
//   .then((data) => {
//     console.log(data);
//     dispatch(setData(data));
//   })
//   .catch(async function () {
//     console.log('no backup data!');
//   })
//   .then(() => {
//     dispatch(setLoading(false));
//   });
// };

export const setLoading = (isLoading) => ({
  type: 'set-app-loading',
  isLoading,
});

export const setNetworkStatus = (networkStatus) => ({
  type: 'set-network-status',
  networkStatus,
});

export const setData = (data) => ({
  type: 'set-app-data',
  data,
});

export const setMenuEnabled = (menuEnabled) => ({
  type: 'set-menu-enabled',
  menuEnabled,
});
