// import { Storage } from "@capacitor/storage";

const projectsURL =
  'https://services7.arcgis.com/oQsBrSs8ovm1GWep/arcgis/rest/services/AMI_Projects_(Public)/FeatureServer/0/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryPoint&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&returnGeodetic=false&outFields=*&returnGeometry=true&returnCentroid=false&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=json&token=';

const projectAttachmentsURL =
  'https://services7.arcgis.com/oQsBrSs8ovm1GWep/arcgis/rest/services/AMI_Projects_(Public)/FeatureServer/0/queryAttachments?objectIds=&globalIds=&definitionExpression=1%3D1&attachmentsDefinitionExpression=&attachmentTypes=&size=&keywords=&resultOffset=&resultRecordCount=&returnUrl=true&f=pjson&token=';

const alertsURL =
  'https://services7.arcgis.com/oQsBrSs8ovm1GWep/ArcGIS/rest/services/AMI_Alerts_(Public)/FeatureServer/0/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&returnGeodetic=false&outFields=*&returnHiddenFields=false&returnGeometry=false&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=json&token=';

const alertAttachmentsURL =
  'https://services7.arcgis.com/oQsBrSs8ovm1GWep/ArcGIS/rest/services/AMI_Alerts_(Public)/FeatureServer/0/queryAttachments?objectIds=&globalIds=&definitionExpression=1%3D1&attachmentsDefinitionExpression=&attachmentTypes=&size=&keywords=&resultOffset=&resultRecordCount=&returnUrl=true&f=pjson&token=';

const firebaseURL = 'https://ami-projects-default-rtdb.firebaseio.com/.json';

export const getAppData = async () => {
  const response = await Promise.all([
    fetch(projectsURL),
    fetch(projectAttachmentsURL),
    fetch(alertsURL),
    fetch(alertAttachmentsURL),
    fetch(firebaseURL),
  ]);
  const projectsData = await response[0].json();
  const projects = projectsData.features;
  const projectAttachmentsData = await response[1].json();
  const projectAttachments = projectAttachmentsData.attachmentGroups;
  const alertsData = await response[2].json();
  const alerts = alertsData.features;
  const alertAttachmentsData = await response[3].json();
  const alertAttachments = alertAttachmentsData.attachmentGroups;
  const firebaseData = await response[4].json();
  const homeText = firebaseData.homeText;
  const faqs = firebaseData.faqs;
  const data = {
    projects,
    projectAttachments,
    alerts,
    alertAttachments,
    homeText,
    faqs,
  };

  return data;
};
