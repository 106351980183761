import React from "react";
import { IonIcon, IonGrid, IonRow, IonCol } from "@ionic/react";
import { logoFacebook, logoTwitter, logoYoutube } from "ionicons/icons";

const ManateeFooter = () => {
  return (
    <>
      <IonGrid className="footer-link-grid">
        <IonRow className="ion-justify-content-center">
          <IonCol size="auto">
            <a
              href="https://www.facebook.com/manatee.county.fl/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <IonIcon icon={logoFacebook} style={{ fontSize: "18px" }} />
            </a>
          </IonCol>

          <IonCol size="auto">
            <a
              href="https://twitter.com/manateegov"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <IonIcon icon={logoTwitter} style={{ fontSize: "18px" }} />
            </a>
          </IonCol>
          <IonCol size="auto">
            <a
              href="https://www.youtube.com/user/ManateeCountyFla"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="YouTube"
            >
              <IonIcon icon={logoYoutube} style={{ fontSize: "18px" }} />
            </a>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            <div style={{ textAlign: "center" }}>
              &#169; Copyright 2020&nbsp;
              <a
                href="https://mymanatee.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Manatee County
              </a>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default ManateeFooter;
