import React, { useState, useEffect } from 'react';
import {
  IonIcon,
  IonButton,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonCardSubtitle,
  IonSpinner,
  IonList,
  IonItem,
  IonLabel,
  IonModal,
} from '@ionic/react';
import axios from 'axios';
import { connect } from '../data/connect';
import * as selectors from '../data/selectors';
import { informationCircleOutline } from 'ionicons/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LightboxModal from './LightboxModal/LightboxModal';
import SocialSharePopover from './SocialSharePopover';
import ImageThumbnails from './ImageThumbails';
import Modal from 'react-bootstrap/Modal';
import './ProjectDetail.css';

// Date formatter
const dateFormatter = (timestamp) => {
  var date = new Date(timestamp);
  var day = [];
  day[0] = 'Sunday';
  day[1] = 'Monday';
  day[2] = 'Tuesday';
  day[3] = 'Wednesday';
  day[4] = 'Thursday';
  day[5] = 'Friday';
  day[6] = 'Saturday';
  var month = [];
  month[0] = 'January';
  month[1] = 'February';
  month[2] = 'March';
  month[3] = 'April';
  month[4] = 'May';
  month[5] = 'June';
  month[6] = 'July';
  month[7] = 'August';
  month[8] = 'September';
  month[9] = 'October';
  month[10] = 'November';
  month[11] = 'December';
  var m = month[date.getMonth()];
  var year = date.getFullYear();
  return m + ` ` + year;
};
// Dollar formatter
const dollarFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});
// Paragraph formatter - Converts ':break' from ArcGIS text to two line breaks in html
const createParagraphs = (text) => {
  return {
    __html: (text || '').replace(/:break/g, '<br /><br />'),
  };
};
// Attachment title formatter - Removes filename from attachment title so it can be used as alternative text
const getAttachmentTitle = (title) => {
  return title.replace(/\.[^/.]+$/, '');
};

const ProjectDetail = ({ projects, projectSlug, projectAttachments }) => {
  // Find the project in global state using the projectSlug prop that was passed from the rendered Route in the Switch on the Explore page
  const project = projects.find((p) => p.attributes.slug === projectSlug);
  const selectedProject = project.attributes;

  //
  const [CIPModalShow, setCIPModalShow] = useState(false);
  const [cipDisabled, setCipDisabled] = useState(true);
  const [isLoading, setisLoading] = useState(true);
  const [cipData, setCipData] = useState(null);
  const [error, setError] = useState(false);
  const cipKey = selectedProject.cipKey;

  // Attachments
  const [images, setImages] = useState();
  const [projectUpdates, setProjectUpdates] = useState();
  const [otherDocuments, setOtherDocuments] = useState();
  useEffect(() => {
    const attachments = projectAttachments.find((a) => a.parentObjectId === selectedProject.FID);
    if (attachments) {
      const images = attachments.attachmentInfos.filter((a) => a.contentType.includes('image'));
      const documents = attachments.attachmentInfos.filter((a) => a.contentType.includes('pdf'));
      // Filter out project updates
      const projectUpdates = documents.filter((doc) => doc.name.includes('Project Update'));

      // Filter out documents that are not project updates
      const otherDocuments = documents.filter((doc) => !doc.name.includes('Project Update'));

      // Sort project updates
      projectUpdates.sort((a, b) => {
        const dateA = getDateFromName(a.name);
        const dateB = getDateFromName(b.name);

        // Sort in descending order of dates
        return dateB - dateA;
      });
      setImages(images);
      setProjectUpdates(projectUpdates);
      setOtherDocuments(otherDocuments);
    }
  }, [projectAttachments, selectedProject]);

  // Helper function to parse date from name
  function getDateFromName(name) {
    const datePattern = /\b\d{4}-\d{1,2}-\d{1,2}\b/;
    const match = name.match(datePattern);
    return match ? new Date(match[0]) : new Date(0); // return Unix epoch time if no date found
  }

  // Image Lightbox
  const [showLightboxModal, setShowLightboxModal] = useState(false);
  const [lightboxContent, setLightboxContent] = useState();
  const [initialSlide, setInitialSlide] = useState(0);
  const openLightbox = (index, images) => {
    setLightboxContent(images);
    setInitialSlide(index);
    setShowLightboxModal(true);
  };

  useEffect(() => {
    axios
      .get(
        'https://www.mymanatee.org/gispm/rest/services/CIP/cipdashboard/FeatureServer/0/query?f=json&where=1=1&outFields=OBJECTID%2CPROJID&returnGeometry=false&spatialRel=esriSpatialRelIntersects&outSR=102100'
      )
      .then((response) => {
        const projects = response.data.features;
        const selectedProject = projects.find((p) => p.attributes.PROJID === cipKey);
        // console.log(response);
        const objectId = selectedProject.attributes.OBJECTID;
        return axios.get(
          `https://www.mymanatee.org/gispm/rest/services/CIP/cipdashboard/FeatureServer/0/query?f=json&where=1=1&objectIds=${objectId}&outFields=*&returnGeometry=false&spatialRel=esriSpatialRelIntersects&outSR=102100`
        );
      })
      .then((response) => {
        setCipData(response.data.features[0].attributes);
      })
      .catch((error) => {
        // console.log('Error fetching CIP data:', error);
        setError(true);
      })
      .finally(() => {
        setCipDisabled(false);
        setisLoading(false);
      });
  }, [selectedProject, cipKey]);

  return (
    <>
      <IonCardHeader>
        {/* Project Status - Set color based on status value */}
        <IonCardSubtitle
          color={
            selectedProject.status === 'inConstruction'
              ? `inconstruction`
              : selectedProject.status === 'inDesign'
              ? `indesign`
              : `completed`
          }
        >
          {selectedProject.status === 'inConstruction'
            ? `In Construction`
            : selectedProject.status === 'inDesign'
            ? `In Design`
            : `Completed`}
        </IonCardSubtitle>
        <IonCardTitle>{selectedProject.title}</IonCardTitle>
        <SocialSharePopover projectName={selectedProject.title} />
      </IonCardHeader>
      <IonCardContent>
        {/* Project location and schedule content  - Set color based on project status value */}
        <div
          className={
            selectedProject.status === 'inConstruction'
              ? `project-location-date-wrap-inconstruction `
              : selectedProject.status === 'inDesign'
              ? `project-location-date-wrap-indesign`
              : `project-location-date-wrap-completed`
          }
        >
          <div className="project-location">
            <p>
              <span className="project-location-title">Location:</span> <span>{selectedProject.location}</span>
            </p>
          </div>
          <div className="project-dates-container">
            {selectedProject.startDateTitle && (
              <div className="project-start-date">
                <p>
                  <span className="project-start-date-title">
                    {selectedProject.startDateTitle}
                    {': '}
                  </span>

                  <span>{selectedProject.startDate}</span>
                </p>
              </div>
            )}
            {selectedProject.endDateTitle && (
              <div className="project-end-date">
                <p>
                  <span className="project-end-date-title">
                    {selectedProject.endDateTitle}
                    {': '}
                  </span>

                  <span>{selectedProject.endDate}</span>
                </p>
              </div>
            )}
          </div>
        </div>
        {/*  Project description and current activites paragraph content */}
        <div className="project-body-text">
          {selectedProject.description && <p dangerouslySetInnerHTML={createParagraphs(selectedProject.description)} />}

          {selectedProject.currentActivities && <h2>Current Activities</h2>}
          <div className="project-body about-body-text">
            {selectedProject.currentActivities && (
              <p
                className="ion-margin-bottom"
                dangerouslySetInnerHTML={createParagraphs(selectedProject.currentActivities)}
              />
            )}
          </div>
          {selectedProject.slug === 'coquina-beach-improvements' && (
            <div>
              <br />
              <iframe
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/c6CjD8UrPUg"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
                style={{ aspectRatio: '16 / 9', width: '100%' }}
              ></iframe>
            </div>
          )}
        </div>
        {/* Project images and lightbox - Images render in a slider and open in popup when clicked on */}
        {images && (
          <>
            <div className="block project-images">
              {images.map((image, index) => (
                <ImageThumbnails onClick={() => openLightbox(index, images)} src={image.url} key={index} alt="Image" />
              ))}
            </div>
            <IonModal
              isOpen={showLightboxModal}
              onDidDismiss={() => setShowLightboxModal(false)}
              swipeToClose={true}
              id="lightboxModal"
              backdropDismiss={false}
              mode="md"
            >
              <LightboxModal
                onDismissModal={() => setShowLightboxModal(false)}
                initialSlide={initialSlide}
                images={lightboxContent}
              />
            </IonModal>
          </>
        )}
        {/* Project documents list  - documents render as seperated buttons */}
        {otherDocuments && (
          <>
            {otherDocuments.length > 0 && (
              <div className="project-body-text">
                <h2>Documents</h2>
              </div>
            )}
            <IonList class="project-list" style={{ maxWidth: 'max-content', marginTop: '-5px' }}>
              {otherDocuments.map((document) => (
                <IonItem
                  lines="none"
                  key={document.id}
                  routerDirection="none"
                  href={document.url}
                  download={document.title}
                  detail={true}
                  target="_blank"
                >
                  <div slot="start" style={{ marginInlineEnd: '0px' }}>
                    <FontAwesomeIcon
                      color="var(--ion-color-primary)"
                      icon={['fas', `file-pdf`]}
                      style={{
                        fontSize: '22px',
                        minWidth: '30px',
                        marginTop: '4px',
                      }}
                    />
                  </div>
                  <IonLabel>
                    <h2
                      style={{
                        fontSize: '16px',
                        marginRight: '10px',
                        marginTop: '0px',
                      }}
                    >
                      {getAttachmentTitle(document.name)}
                    </h2>
                  </IonLabel>
                </IonItem>
              ))}
            </IonList>
          </>
        )}
        {/* Project documents list  - documents render as seperated buttons */}
        {projectUpdates && (
          <>
            {projectUpdates.length > 0 && (
              <div className="project-body-text">
                <h2>Project Updates</h2>
              </div>
            )}
            <IonList class="project-list" style={{ maxWidth: 'max-content', marginTop: '-5px' }}>
              {projectUpdates.map((document) => (
                <IonItem
                  lines="none"
                  key={document.id}
                  routerDirection="none"
                  href={document.url}
                  download={document.title}
                  detail={true}
                  target="_blank"
                >
                  <div slot="start" style={{ marginInlineEnd: '0px' }}>
                    <FontAwesomeIcon
                      color="var(--ion-color-primary)"
                      icon={['fas', `file-pdf`]}
                      style={{
                        fontSize: '22px',
                        minWidth: '30px',
                        marginTop: '4px',
                      }}
                    />
                  </div>
                  <IonLabel>
                    <h2
                      style={{
                        fontSize: '16px',
                        marginRight: '10px',
                        marginTop: '0px',
                      }}
                    >
                      {getAttachmentTitle(document.name)}
                    </h2>
                  </IonLabel>
                </IonItem>
              ))}
            </IonList>
          </>
        )}
        {/* Project CIP Data - If project has cipKey assosciation in the county database, render the button and load data */}
        {selectedProject.cipKey && (
          <>
            <IonButton
              color="secondary"
              fill="solid"
              onClick={() => setCIPModalShow(true)}
              disabled={cipDisabled || error}
              style={{ marginTop: '10px' }}
            >
              {cipDisabled ? (
                <IonSpinner slot="start" name="lines-small" className="cip-spinner" />
              ) : (
                <IonIcon icon={informationCircleOutline} slot="start"></IonIcon>
              )}
              Manatee County CIP Data
            </IonButton>
            {/* If CIP data is successfully fetched from county database, render CIP modal */}
            {cipData && (
              <Modal
                size="lg"
                dialogClassName="modal-component"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                className="cip-modal"
                projectid={selectedProject.id}
                show={CIPModalShow}
                onHide={() => setCIPModalShow(false)}
                restoreFocus={true}
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">CIP Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {!isLoading && (
                    <>
                      <h2 className="project-name">{cipData.PROJNAME ? cipData.PROJNAME : 'N/A'}</h2>
                      <p>
                        <span style={{ fontWeight: '600', color: '#000' }}>Project ID:</span>{' '}
                        {cipData.PROJECT_NO ? cipData.PROJECT_NO : 'N/A'} <br />
                        <span style={{ fontWeight: '600', color: '#000' }}>CIP Key: </span>
                        {cipData.PROJID ? cipData.PROJID : 'N/A'}
                      </p>
                      <table className="status">
                        <tbody>
                          <tr style={{ height: '26px' }}>
                            <td>On Budget</td>
                            <td>On Schedule</td>
                          </tr>
                        </tbody>
                      </table>
                      <p style={{ marginBottom: '0px' }}>
                        <span
                          style={{
                            fontWeight: '600',
                            color: '#000',
                          }}
                        >
                          Description:
                        </span>
                        <br />
                        {cipData.PROJDESC && cipData.PROJDESC}
                      </p>

                      <table className="data">
                        <tbody>
                          <tr>
                            <td>
                              <span style={{ fontWeight: '600', color: '#000' }}>Fiscal Year:</span> <br />
                              {cipData.FISCALYR ? cipData.FISCALYR : 'N/A'}
                            </td>
                            <td>
                              <span style={{ fontWeight: '600', color: '#000' }}>
                                Funding Source: <br />
                              </span>
                              {cipData.FUNDSOURCE ? cipData.FUNDSOURCE : 'N/A'}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span style={{ fontWeight: '600', color: '#000' }}>
                                Project Funding: <br />
                              </span>
                              {cipData.ESTCOST ? dollarFormatter.format(cipData.ESTCOST) : 'N/A'}
                            </td>
                            <td>
                              <span style={{ fontWeight: '600', color: '#000' }}>
                                Actual Cost: <br />
                              </span>
                              {cipData.ACTCOST ? dollarFormatter.format(cipData.ACTCOST) : 'N/A'}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span style={{ fontWeight: '600', color: '#000' }}>
                                Estimated Start Date: <br />
                              </span>
                              {cipData.PLANSTART ? dateFormatter(cipData.PLANSTART) : 'N/A'}
                            </td>
                            <td>
                              <span style={{ fontWeight: '600', color: '#000' }}>
                                Actual Start Date: <br />
                              </span>
                              {cipData.ACTSTART ? dateFormatter(cipData.ACTSTART) : 'N/A'}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span style={{ fontWeight: '600', color: '#000' }}>
                                Estimated End Date: <br />
                              </span>
                              {cipData.PLANEND ? dateFormatter(cipData.PLANEND) : 'N/A'}
                            </td>
                            <td>
                              <span style={{ fontWeight: '600', color: '#000' }}>
                                Actual End Date: <br />
                              </span>
                              {cipData.ACTEND ? dateFormatter(cipData.ACTEND) : 'N/A'}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      {cipData.DOCPATH && (
                        <IonButton
                          color="secondary"
                          fill="outline"
                          download
                          href={cipData.DOCPATH}
                          target="_blank"
                          style={{ marginBottom: '15px' }}
                        >
                          <FontAwesomeIcon
                            icon={['far', 'file-pdf']}
                            style={{ fontSize: '20px', marginRight: '7px' }}
                          />
                          Project Fact Sheet
                        </IonButton>
                      )}
                    </>
                  )}
                </Modal.Body>
              </Modal>
            )}
          </>
        )}
      </IonCardContent>
    </>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    projects: state.data.projects,
    projectAttachments: selectors.getProjectAttachments(state),
  }),
  component: React.memo(ProjectDetail),
});
