import React from "react";
import { IonItem, IonLabel, IonList, IonIcon } from "@ionic/react";
import { checkmarkCircle } from "ionicons/icons";

const DocumentList = () => {
  return (
    <IonList class="project-list document-list">
      <IonItem lines="none" button id="alert-button">
        <IonIcon
          ios={checkmarkCircle}
          slot="start"
          size="large"
          color="success"
          className="alert-icon"
        />
        <IonLabel>
          <span>
            Resource docouments will be posted here as they made available.
          </span>
        </IonLabel>
      </IonItem>
    </IonList>
  );
};

export default DocumentList;
