/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane, setupConfig } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

// App state
import { connect } from './data/connect';
import { AppContextProvider } from './data/AppContext';
import { loadAppData } from './data/app.actions';

// Pages and UI Components
import Home from './pages/Home';
import Explore from './pages/Projects';
import Resources from './pages/Resources';
import Contact from './pages/Contact';
import Seo from './components/Seo';
import Menu from './components/Menu';
import Loading from './components/Loading';

// Global styling
import './theme/variables.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@ionic/react/css/core.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

// Object for configuring OneSignal
import ReactOneSignal from 'react-onesignal';

// FontAwesome Icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';

// Ionic configuration setup - Set mode to always iOS theme - Enable status bar tap scroll to top - Disable swipe back gesture
setupConfig({
  mode: 'ios',
  statusTap: true,
  swipeBackEnabled: false,
});

// Add imported FontAwesome icon sets to global FontAwesome library
library.add(far, fas);

// OneSignal configuration options
const options = {
  safari_web_id: 'web.amiprojects.io',
  allowLocalhostAsSecureOrigin: false,
  requiresUserPrivacyConsent: false,
  persistNotification: false,
  autoResubscribe: true,
  autoRegister: false,
  notifyButton: {
    enable: false,
  },
};

// Main App Component
const App = () => {
  return (
    <AppContextProvider>
      <AppConnected />
    </AppContextProvider>
  );
};
export default App;

// App Consumert Component
const AppConsumer = ({ loadAppData, loading }) => {
  useEffect(() => {
    ReactOneSignal.initialize('12bc96fe-6121-466b-95c3-f3a8ca976480', options); // Initialize OneSignal with options set above
    loadAppData(); // Fetch projects and alerts from ArcGIS REST endpoint then store returned data in application state
  }, []);
  // If app is fetching data - render loading screen
  if (loading) {
    return <Loading />;
  }
  // If app has finished fetching data - render IonApp
  return (
    <IonApp>
      <IonReactRouter>
        <Seo /> {/* Update meta tags using Helmet and log page views in Google Analtics on route changes */}
        <IonSplitPane contentId="main" when="xl">
          <Menu />
          <IonRouterOutlet id="main">
            <Route exact path="/" render={() => <Home />} />
            <Route path="/projects" render={() => <Explore />} />
            <Route exact path="/resources" render={() => <Resources />} />
            <Route exact path="/contact" render={() => <Contact />} />
            <Redirect to="/" />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

const AppConnected = connect({
  mapStateToProps: (state) => ({
    loading: state.data.loading,
  }),
  mapDispatchToProps: {
    loadAppData,
  },
  component: AppConsumer,
});
