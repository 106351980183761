import { createSelector } from "reselect";

const getProjects = (state) => {
  return state.data.projects;
};

const getIdParam = (_state, props) => {
  return props.match.params["id"];
};

export const getProject = createSelector(
  getProjects,
  getIdParam,
  (projects, id) => {
    return projects.find((p) => p.slug === id);
  }
);

export const getProjectAttachments = (state) => {
  return state.data.projectAttachments;
};

export const getAlerts = (state) => {
  return state.data.alerts;
};

export const getAlertAttachments = (state) => {
  return state.data.alertAttachments;
};
