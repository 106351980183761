/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { IonItem, IonLabel, IonIcon, IonList } from '@ionic/react';
import { checkmarkCircle } from 'ionicons/icons';
import { connect } from '../data/connect';
import * as selectors from '../data/selectors';
import AlertsListItem from './AlertsListItem';

const AlertsList = ({ alerts }) => {
  const [activeAlerts, setActiveAlerts] = useState(alerts);

  useEffect(() => {
    const currentDate = new Date();
    activeAlerts.forEach((alert) => {
      const startDate = new Date(alert.attributes.startDate);
      const endDate = new Date(alert.attributes.endDate);
      if (startDate < currentDate && endDate > currentDate) {
        return;
      } else {
        const activeAlerts = alerts.filter((a) => a !== alert);
        setActiveAlerts(activeAlerts);
      }
    });
  }, [activeAlerts]);

  if (activeAlerts.length === 0) {
    return (
      <IonList className="project-list">
        <IonItem lines="none" id="alert-button">
          <IonIcon ios={checkmarkCircle} slot="start" size="large" color="success" className="alert-icon" />
          <IonLabel>
            <h2 style={{ fontWeight: '600' }}>No Active Alerts</h2>
            <span>No alerts are currently issued</span>
          </IonLabel>
        </IonItem>
      </IonList>
    );
  }

  return (
    <div>
      <IonList className="alert-list" style={{ marginTop: '10px' }}>
        {activeAlerts.map((alert) => (
          <AlertsListItem key={alert.attributes.OBJECTID} alert={alert.attributes} />
        ))}
      </IonList>
    </div>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    alerts: selectors.getAlerts(state),
    alertAttachments: selectors.getAlertAttachments(state),
  }),
  component: React.memo(AlertsList),
});
