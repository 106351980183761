import React, { useRef } from 'react';
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  IonItem,
  IonInput,
  IonLabel,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonList,
  IonIcon,
} from '@ionic/react';
import validate from '../components/validation/validateInfo';
import useForm from '../hooks/ContactForm';
import { call, mail } from 'ionicons/icons';
import AlertListPopover from '../components/AlertListPopover';
import ThankYou from '../components/ThankYou';

const Contact = ({ submitForm }) => {
  const { handleChange, handleBlur, handleSubmit, values, errors, isSubmitting } = useForm(submitForm, validate);

  const contactTitle = useRef(null);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>AMI Projects</IonTitle>
          <AlertListPopover />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol sizeXl="8.5" sizeLg="8.5" sizeMd="7" sizeSm="12" sizeXs="12">
              <IonCard mode="ios">
                <IonCardHeader>
                  <IonCardTitle>
                    <span className="focused-title" tabIndex="-1" ref={contactTitle}>
                      Have Questions?
                    </span>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <p>
                    If you still have questions after viewing the information on this website, please feel free to
                    contact a project representative by phone, email, or contact form.
                  </p>

                  <IonList className="project-list">
                    <IonGrid>
                      <IonRow>
                        <IonCol sizeLg="6" size="12">
                          <IonItem lines="none" href="tel:+1.941.306.4660" target="_blank">
                            <IonIcon
                              slot="start"
                              icon={call}
                              color="completed"
                              style={{
                                marginLeft: '10px',
                                marginRight: '10px',
                              }}
                            />
                            <IonLabel>
                              <h2>(941) 306-4660</h2>
                              <p>24/7 Hotline</p>
                            </IonLabel>
                          </IonItem>
                        </IonCol>
                        <IonCol>
                          <IonItem lines="none" href="mailto:info@amiprojects.io" target="_blank">
                            <IonIcon
                              slot="start"
                              icon={mail}
                              color="completed"
                              style={{
                                marginLeft: '10px',
                                marginRight: '10px',
                              }}
                            />
                            <IonLabel>
                              <h2>info@amiprojects.io</h2>
                              <p>Email Address</p>
                            </IonLabel>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonList>
                  {!isSubmitting ? (
                    <form onSubmit={handleSubmit} id="contactForm">
                      <IonList className="project-list">
                        <div className="project-body-text">
                          <h2 className="form-title">Fill out a contact form.</h2>
                        </div>
                        <IonRow>
                          <IonCol>
                            <IonItem mode="md">
                              <IonLabel position="floating">First Name</IonLabel>
                              <IonInput
                                type="text"
                                name="firstName"
                                value={values.firstName}
                                onIonChange={handleChange}
                                onIonBlur={handleBlur}
                              />
                            </IonItem>
                            {errors.firstName && (
                              <p
                                style={{
                                  fontSize: '13px',
                                  marginLeft: '16px',
                                  color: 'var(--ion-color-danger)',
                                }}
                              >
                                {errors.firstName}
                              </p>
                            )}
                          </IonCol>
                          <IonCol>
                            <IonItem mode="md">
                              <IonLabel position="floating">Last Name</IonLabel>
                              <IonInput
                                type="text"
                                maxlength={12}
                                name="lastName"
                                value={values.lastName}
                                onIonChange={handleChange}
                                onIonBlur={handleBlur}
                                // autocomplete="name"
                              />
                            </IonItem>
                            {errors.lastName && (
                              <p
                                style={{
                                  fontSize: '13px',
                                  marginLeft: '16px',
                                  color: 'var(--ion-color-danger)',
                                }}
                              >
                                {errors.lastName}
                              </p>
                            )}
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol>
                            <IonItem mode="md">
                              <IonLabel position="floating">Phone Number</IonLabel>
                              <IonInput
                                maxlength={12}
                                type="tel"
                                name="phoneNumber"
                                value={values.phoneNumber}
                                onIonChange={handleChange}
                                onIonBlur={handleBlur}
                              />
                            </IonItem>
                            {errors.phoneNumber && (
                              <p
                                style={{
                                  fontSize: '13px',
                                  marginLeft: '16px',
                                  color: 'var(--ion-color-danger)',
                                }}
                              >
                                {errors.phoneNumber}
                              </p>
                            )}
                          </IonCol>
                          <IonCol>
                            <IonItem mode="md">
                              <IonLabel position="floating">Email Address</IonLabel>
                              <IonInput
                                type="email"
                                name="email"
                                value={values.email}
                                onIonChange={handleChange}
                                onIonBlur={handleBlur}
                              />
                            </IonItem>
                            {errors.email && (
                              <p
                                style={{
                                  fontSize: '13px',
                                  marginLeft: '16px',
                                  color: 'var(--ion-color-danger)',
                                }}
                              >
                                {errors.email}
                              </p>
                            )}
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol>
                            <IonItem mode="md">
                              <IonLabel position="floating">Message</IonLabel>
                              <IonInput
                                type="text"
                                name="message"
                                value={values.message}
                                onIonChange={handleChange}
                                onIonBlur={handleBlur}
                              />
                            </IonItem>
                            {errors.message && (
                              <p
                                style={{
                                  fontSize: '13px',
                                  marginLeft: '16px',
                                  color: 'var(--ion-color-danger)',
                                }}
                              >
                                {errors.message}
                              </p>
                            )}
                            <IonButton
                              mode="ios"
                              fill="solid"
                              color="secondary"
                              className="ion-margin-vertical"
                              type="submit"
                            >
                              Send Message
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      </IonList>
                    </form>
                  ) : (
                    <ThankYou />
                  )}
                </IonCardContent>
              </IonCard>
            </IonCol>

            <IonCol sizeXl="3.5" sizeLg="3.5" sizeMd="5" sizeXs="12">
              <IonCard mode="ios">
                <IonCardHeader>
                  <IonCardTitle className="project-list" style={{ fontSize: '21px' }}>
                    Have concerns regarding other Manatee County maintenance or construction activities?
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonButton
                    color="secondary"
                    fill="solid"
                    className="button-text-white"
                    href="https://www.mymanatee.org/departments/public_works/report_an_issue"
                    target="_blank"
                  >
                    Report Them Here
                  </IonButton>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Contact;
