const validate = (values, onBlurStatus) => {
  let errors = {};
  const { email, firstName, lastName, phoneNumber, message } = values;

  //email

  if (onBlurStatus.email && email) {
    if (
      email.length <= 3 ||
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
    ) {
      errors.email = "Email address is invalid";
    }
  }

  if (onBlurStatus.email && !email) {
    errors.email = "Email required";
  }

  if (onBlurStatus.phoneNumber && !phoneNumber) {
    errors.phoneNumber = "Phone number required";
  }

  //first name
  if (onBlurStatus.firstName && firstName.trim().length <= 2) {
    errors.firstName = "Please enter your first name";
  }
  //last name
  if (onBlurStatus.lastName && lastName.trim().length <= 2) {
    errors.lastName = "Please enter your last name";
  }

  //message
  if (onBlurStatus.message && message.length < 10) {
    errors.message = "Please enter a more detailed message";
  }

  return errors;
};

export default validate;
