/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { IonIcon, IonPopover, IonButtons, IonButton, IonBadge } from '@ionic/react';
import { notifications } from 'ionicons/icons';
import AlertsList from './AlertsList';
import { connect } from '../data/connect';
import * as selectors from '../data/selectors';

const AlertListPopover = ({ alerts }) => {
  const [popoverState, setShowPopover] = useState({ showPopover: false });
  const [activeAlerts, setActiveAlerts] = useState(alerts);

  useEffect(() => {
    const currentDate = new Date();
    activeAlerts.forEach((alert) => {
      const startDate = new Date(alert.attributes.startDate);
      const endDate = new Date(alert.attributes.endDate);
      if (startDate < currentDate && endDate > currentDate) {
        return;
      } else {
        const activeAlerts = alerts.filter((a) => a !== alert);
        setActiveAlerts(activeAlerts);
      }
    });
  }, [activeAlerts]);

  return (
    <>
      <IonPopover
        mode="ios"
        cssClass="alert-list-popover"
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() => setShowPopover({ showPopover: false })}
      >
        <AlertsList />
      </IonPopover>
      <IonButtons slot="end">
        <IonButton
          onClick={(e) => {
            e.persist();
            setShowPopover({ showPopover: true, event: e });
          }}
          aria-label="Alerts"
          className="notification-button"
        >
          <IonIcon icon={notifications} color="light" style={{ fontSize: '27px' }}></IonIcon>
        </IonButton>
        {activeAlerts.length > 0 && (
          <IonBadge slot="end" color="danger" className="notification-badge">
            {activeAlerts.length}
          </IonBadge>
        )}
      </IonButtons>
    </>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    alerts: selectors.getAlerts(state),
  }),
  component: React.memo(AlertListPopover),
});
