import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  IonIcon,
  IonPopover,
  IonButtons,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
} from "@ionic/react";
import {
  logoFacebook,
  logoLinkedin,
  logoTwitter,
  shareSocial,
} from "ionicons/icons";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import ReactTooltip from "react-tooltip";

const SocialSharePopover = ({ projectName }) => {
  let location = useLocation();
  const [popoverState, setShowPopover] = useState({ showPopover: false });
  return (
    <>
      <IonPopover
        mode="ios"
        cssClass="social-share-popover"
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() => setShowPopover({ showPopover: false })}
      >
        <IonList className="project-list">
          <FacebookShareButton
            url={`https://amiprojects.io${location.pathname}`}
          >
            <IonItem lines="none" id="alert-button" detail={false}>
              <IonIcon
                ios={logoFacebook}
                slot="start"
                size="large"
                className="alert-icon"
                style={{ color: "#4267B2" }}
              />
              <IonLabel>
                <h2>Facebook</h2>
              </IonLabel>
            </IonItem>
          </FacebookShareButton>
          <TwitterShareButton
            url={`https://amiprojects.io${location.pathname}`}
          >
            <IonItem lines="none" button id="alert-button" detail={false}>
              <IonIcon
                ios={logoTwitter}
                slot="start"
                size="large"
                className="alert-icon"
                style={{ color: "#1DA1F2" }}
              />
              <IonLabel>
                <h2>Twitter</h2>
              </IonLabel>
            </IonItem>
          </TwitterShareButton>
          <LinkedinShareButton
            title={projectName}
            source="AMI Projects"
            url={`https://amiprojects.io${location.pathname}`}
          >
            <IonItem lines="none" button id="alert-button" detail={false}>
              <IonIcon
                ios={logoLinkedin}
                slot="start"
                size="large"
                className="alert-icon"
                style={{ color: "#0e76a8" }}
              />
              <IonLabel>
                <h2>LinkedIn</h2>
              </IonLabel>
            </IonItem>
          </LinkedinShareButton>
        </IonList>
      </IonPopover>
      <IonButtons style={{ position: "absolute", top: "10px", right: "10px" }}>
        <IonButton
          onClick={(e) => {
            e.persist();
            setShowPopover({ showPopover: true, event: e });
          }}
          aria-label="Share"
          data-tip="Share Project"
          data-place="left"
          data-effect="solid"
          data-for="share-tooltip"
          data-offset="{'right': 10}"
        >
          <IonIcon
            icon={shareSocial}
            color="secondary"
            style={{ fontSize: "23px" }}
          />
        </IonButton>
      </IonButtons>
      <ReactTooltip id="share-tooltip" className="custom-tooltip" />
    </>
  );
};

export default SocialSharePopover;
