import React, { useState } from 'react';
import { IonButtons, IonButton } from '@ionic/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import AlertSettingsModal from './AlertSettingsModal';
import ReactOneSignal, { useOneSignalSetup } from 'react-onesignal';

const AlertPreferences = () => {
  const [AlertSettingsModalShow, setAlertSettingsModalShow] = useState(false);
  const [alertBellHidden, setAlertBellHidden] = useState(true);
  const [bellTooltipText, setBellTooltipText] = useState('');
  const [isPushEnabled, setIsPushEnabled] = useState(false);
  const [bellIcon, setBellIcon] = useState('bell-plus');
  const [bellIconColor, setBellIconColor] = useState('var(--ion-color-primary)');

  useOneSignalSetup(async () => {
    var OneSignal = window['OneSignal'];
    const permissionState = await ReactOneSignal.getNotificationPermission();
    if (permissionState === 'default') {
      setBellTooltipText(`Enable Alerts`);
    } else if (permissionState === 'granted') {
      getSubscriptionState().then(function (state) {
        if (state.isPushEnabled) {
          setIsPushEnabled(true);
          setBellIcon('bell-on');
          setBellTooltipText(`Mute Alerts`);
        } else {
          setIsPushEnabled(false);
          setBellIcon('bell-slash');
          setBellIconColor('var(--ion-color-danger)');
          setBellTooltipText(`Unmute Alerts`);
        }
      });
    } else if (permissionState === 'denied') {
      setBellIcon('bell-slash');
      setBellTooltipText(`You've blocked alerts`);
    }
    setAlertBellHidden(false);
    OneSignal.on('notificationPermissionChange', function (permissionChange) {
      var newPermissionState = permissionChange.to;
      if (newPermissionState === 'default') {
        setBellIcon('bell-plus');
      } else if (newPermissionState === 'granted') {
        setBellIcon('bell-on');
      } else if (newPermissionState === 'denied') {
        setBellIcon('bell-slash');
        setBellTooltipText(`You've blocked alerts`);
      }
    });
    OneSignal.on('subscriptionChange', function (isSubscribed) {
      if (isSubscribed) {
        setBellIcon('bell-on');
        setBellIconColor('var(--ion-color-primary)');
        setBellTooltipText(`Mute Alerts`);
        setIsPushEnabled(true);
      } else {
        setBellIcon('bell-slash');
        setBellIconColor('var(--ion-color-danger)');
        setBellTooltipText(`Unmute Alerts`);
        setIsPushEnabled(false);
      }
    });

    function getSubscriptionState() {
      return Promise.all([OneSignal.isPushNotificationsEnabled(), OneSignal.isOptedOut()]).then(function (result) {
        var isPushEnabled = result[0];
        var isOptedOut = result[1];

        return {
          isPushEnabled: isPushEnabled,
          isOptedOut: isOptedOut,
        };
      });
    }
  });

  const handleBellClick = (event) => {
    var OneSignal = window['OneSignal'];
    getSubscriptionState().then(function (state) {
      if (state.isPushEnabled) {
        OneSignal.setSubscription(false);
      } else {
        if (state.isOptedOut) {
          OneSignal.setSubscription(true);
        } else {
          ReactOneSignal.showSlidedownPrompt({ force: true });
        }
      }
    });
    event.preventDefault();
    function getSubscriptionState() {
      return Promise.all([OneSignal.isPushNotificationsEnabled(), OneSignal.isOptedOut()]).then(function (result) {
        var isPushEnabled = result[0];
        var isOptedOut = result[1];

        return {
          isPushEnabled: isPushEnabled,
          isOptedOut: isOptedOut,
        };
      });
    }
  };

  const handleFilterClick = () => {
    ReactOneSignal.showCategorySlidedown({ force: true });
  };

  return (
    <div className="alert-buttons">
      <IonButtons
        style={{
          position: 'absolute',
          top: '15px',
          right: '15px',
        }}
      >
        <IonButton
          onClick={() => setAlertSettingsModalShow(true)}
          aria-label="Email alerts"
          data-tip="Email Alerts"
          data-place="left"
          data-effect="solid"
          data-for="email-tooltip"
          data-offset="{'right': 10}"
          data-class="custom-tooltip"
          hidden={alertBellHidden}
          disabled={!isPushEnabled}
        >
          <FontAwesomeIcon
            icon={['far', 'envelope']}
            style={{
              fontSize: '26px',
              marginRight: '4px',
            }}
          />
        </IonButton>
        <ReactTooltip id="email-tooltip" />
        <IonButton
          onClick={handleFilterClick}
          aria-label="Update alert preferences"
          data-tip="Update alert preferences"
          data-place="left"
          data-effect="solid"
          data-for="filter-tooltip"
          data-offset="{'right': 10}"
          data-class="custom-tooltip"
          hidden={alertBellHidden}
          disabled={!isPushEnabled}
        >
          <FontAwesomeIcon icon={['far', 'sliders-v']} style={{ fontSize: '23px' }} />
        </IonButton>
        <ReactTooltip id="filter-tooltip" />
        <IonButton
          onClick={handleBellClick}
          aria-label="Toggle alerts"
          data-tip={bellTooltipText}
          data-place="left"
          data-effect="solid"
          data-for="alerts-tooltip"
          data-offset="{'right': 10}"
          data-class="custom-tooltip"
          hidden={alertBellHidden}
        >
          <FontAwesomeIcon icon={['far', `${bellIcon}`]} style={{ fontSize: '26px', color: `${bellIconColor}` }} />
        </IonButton>
        <ReactTooltip id="alerts-tooltip" />
      </IonButtons>
      <AlertSettingsModal show={AlertSettingsModalShow} onHide={() => setAlertSettingsModalShow(false)} />
    </div>
  );
};

export default AlertPreferences;
