/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { connect } from '../data/connect';

function truncateMetaDescription(str, length, ending) {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = '';
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
}

const Seo = (props) => {
  let location = useLocation();
  let currentUrl = 'https://amiprojects.io' + location.pathname;
  let quote = props.quote !== undefined ? props.quote : '';
  let image = props.image !== undefined ? props.image : 'https://amiprojects.io/assets/share.jpg';
  let hashtag = props.hashtag !== undefined ? props.hashtag : '#AMIprojects';
  const [title, setTitle] = useState('AMI Projects');
  const [description, setDescription] = useState(
    "AMI Projects is Anna Maria Island's official website for the capitial improvement projects happening on the island. View project information and stay up to date with real time alerts."
  );

  useEffect(() => {
    const slug = location.pathname.split('/').pop();
    // const slugSpaced = slug.replace(/-/g, " ");
    // const pageTitle = slugSpaced.replace(/\w\S*/g, (w) =>
    //   w.replace(/^\w/, (c) => c.toUpperCase())
    // );
    if (location.pathname.startsWith('projects/')) {
      const project = props.projects.find((p) => p.attributes.slug === slug);
      const selectedProject = project.attributes;
      const description =
        "Learn about Anna Maria Island's " +
        selectedProject.title +
        'capital improvement project. ' +
        selectedProject.description;
      setDescription(truncateMetaDescription(description, 250));
      setTitle(selectedProject.title);
      return;
    }
    if (location.pathname === '/') {
      setTitle('Home');
      setDescription(
        "AMI Projects is Anna Maria Island's official website for the capitial improvement projects happening on the island. View project information and stay up to date with real time alerts."
      );
    }
    if (location.pathname === '/contact') {
      setTitle('Contact');
      setDescription(
        'Have questions or concerns about an Anna Maria Island improvement project? Reach out to a project representative by phone, email, or contact form any time time of the day.'
      );
    }
    if (location.pathname === '/resources') {
      setTitle('Resources');
      setDescription(
        'View documents and additional resources related to the improvement projects happening throughout Anna Maria Island.'
      );
    }
  }, [location.pathname]);

  const history = useHistory();
  useEffect(() => {
    const analytics = getAnalytics();
    if (history.length > 1) {
      logEvent(analytics, 'page_view', { page_title: title });
    }
  }, [title]);
  return (
    <>
      <Helmet titleTemplate="%s | AMI Projects" title={title}>
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta property="type" content="website" />
        <meta property="url" content={currentUrl} />
        <meta name="robots" content="noodp" />
        <meta property="title" content={title} />
        <meta property="quote" content={quote} />
        <meta name="description" content={description} />
        <meta property="image" content={image} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:quote" content={quote} />
        <meta property="og:hashtag" content={hashtag} />
        <meta property="og:image" content={image} />
        <meta content="image/*" property="og:image:type" />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:site_name" content="AMI Projects" />
        <meta property="og:description" content={description} />
      </Helmet>
    </>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    projects: state.data.projects,
  }),
  component: Seo,
});
