import { useState, useEffect } from "react";
import validate from "../components/validation/validateInfo";
import emailjs from "emailjs-com";

const useForm = (callback) => {
  const [values, setValues] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    message: "",
  });

  const [onBlurStatus, setOnBlurStatus] = useState({
    email: false,
    firstName: false,
    lastName: false,
    phoneNumber: false,
    message: false,
  });

  const [errors, setErrors] = useState({});

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setOnBlurStatus({ ...onBlurStatus, [name]: true });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrors(validate(values, onBlurStatus));

    if (Object.keys(errors).length === 0) {
      try {
        setIsSubmitting(true);
      } catch (err) {
        console.log(err);
      }

      emailjs
        .sendForm(
          "service_iovnqdg",
          "template_3fn61il",
          "#contactForm",
          "user_80rb8UkpayCO1RRzKFSjB"
        )
        .then((result) => {
          setIsSubmitting(true);
          console.log("success", result.status, result.text);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    setErrors(validate(values, onBlurStatus));
  }, [values, onBlurStatus]);

  return {
    handleChange,
    values,
    handleSubmit,
    errors,
    onBlurStatus,
    handleBlur,
    isSubmitting,
  };
};

export default useForm;
