/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.jsx';
import { initializeApp } from 'firebase/app';
import * as serviceWorker from './serviceWorker';

// Firebase configuration options
const firebaseConfig = {
  apiKey: 'AIzaSyDkoJba7Wk9pSDVjCloyU4uYtDrO5G-nv8',
  authDomain: 'ami-projects.firebaseapp.com',
  databaseURL: 'https://ami-projects-default-rtdb.firebaseio.com',
  projectId: 'ami-projects',
  storageBucket: 'ami-projects.appspot.com',
  messagingSenderId: '293536831702',
  appId: '1:293536831702:web:491b734dc105882d871177',
  measurementId: 'G-SDW4P2SCR5',
};
// Initialize Firebase app
const firebaseApp = initializeApp(firebaseConfig);

// Render main App component
ReactDOM.render(<App />, document.getElementById('root'));

// Register service worker
serviceWorker.register();
