import React, { useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle, IonPopover } from '@ionic/react';
import { compass, documentText, call, home, ellipsisHorizontal, accessibility, lockClosed } from 'ionicons/icons';
import ManateeFooter from './ManateeFooter';
import PrivacyModal from '../components/PrivacyModal';
import AccessibilityModal from '../components/AccessibilityModal';

const Menu = () => {
  const location = useLocation();
  const [popoverState, setShowPopover] = useState({ showPopover: false });
  const [PrivacyModalShow, setPrivacyModalShow] = useState(false);
  const [AccessibilityModalShow, setAccessibilityModalShow] = useState(false);

  return (
    <IonMenu contentId="main" side="start" type="push" className="menu-left" id="menuLeft" swipeGesture={true}>
      <IonContent className="no-background" forceOverscroll={false}>
        <div className="menu-wrapper">
          <IonList id="menu-list">
            <div className="logo-img">
              <img src="/assets/AMI-Icon-orange.png" alt="AMI Logo" width="215" height="94" />
            </div>

            <IonMenuToggle autoHide={false}>
              <IonItem
                className={location.pathname === '/' ? 'selected' : ''}
                routerLink="/"
                routerDirection="none"
                lines="none"
                detail={location.pathname === '/' ? true : false}
              >
                <IonIcon slot="start" ios={home} md={home} />
                <IonLabel>Home</IonLabel>
              </IonItem>
            </IonMenuToggle>

            <IonMenuToggle autoHide={false}>
              <IonItem
                className={
                  location.pathname === '/projects' ||
                  location.pathname === '/projects/cortez-bridge-utility-relocation-project' ||
                  location.pathname === '/projects/coquina-beach-improvements' ||
                  location.pathname === '/projects/force-main-1' ||
                  location.pathname === '/projects/ami-watermain-improvements' ||
                  location.pathname === '/projects/force-main-8' ||
                  location.pathname === '/projects/force-main-15' ||
                  location.pathname === '/projects/holmes-boulevard-force-main-replacement' ||
                  location.pathname === '/projects/bradenton-beach-sewer-rehabilitation'
                    ? 'selected'
                    : ''
                }
                routerLink={`/projects/bradenton-beach-sewer-improvements`}
                routerDirection="none"
                lines="none"
                detail={
                  location.pathname === '/projects' ||
                  location.pathname === '/projects/cortez-bridge-utility-relocation-project' ||
                  location.pathname === '/projects/coquina-beach-improvements' ||
                  location.pathname === '/projects/force-main-1' ||
                  location.pathname === '/projects/ami-watermain-improvements' ||
                  location.pathname === '/projects/force-main-8' ||
                  location.pathname === '/projects/force-main-15' ||
                  location.pathname === '/projects/holmes-boulevard-force-main-replacement' ||
                  location.pathname === '/projects/bradenton-beach-sewer-rehabilitation'
                    ? true
                    : false
                }
              >
                <IonIcon slot="start" ios={compass} md={compass} />
                <IonLabel>Projects</IonLabel>
              </IonItem>
            </IonMenuToggle>

            <IonMenuToggle autoHide={false}>
              <IonItem
                className={location.pathname === '/resources' ? 'selected' : ''}
                routerLink="/resources"
                routerDirection="none"
                lines="none"
                detail={location.pathname === '/resources' ? true : false}
              >
                <IonIcon slot="start" ios={documentText} md={documentText} />
                <IonLabel>Resources</IonLabel>
              </IonItem>
            </IonMenuToggle>

            <IonMenuToggle autoHide={false}>
              <IonItem
                className={location.pathname === '/contact' ? 'selected' : ''}
                routerLink="/contact"
                routerDirection="none"
                lines="none"
                detail={location.pathname === '/contact' ? true : false}
              >
                <IonIcon slot="start" ios={call} md={call} />
                <IonLabel>Contact</IonLabel>
              </IonItem>
            </IonMenuToggle>

            <IonItem
              lines="none"
              button
              detail={false}
              onClick={(e) => {
                e.persist();
                setShowPopover({ showPopover: true, event: e });
              }}
            >
              <IonIcon slot="start" ios={ellipsisHorizontal} />
              <IonLabel>More</IonLabel>
            </IonItem>
            <IonPopover
              mode="ios"
              cssClass="more-menu-popover"
              event={popoverState.event}
              isOpen={popoverState.showPopover}
              onDidDismiss={() => setShowPopover({ showPopover: false })}
            >
              <IonList className="project-list">
                <IonItem button lines="none" detail={true} onClick={() => setAccessibilityModalShow(true)}>
                  <IonIcon slot="start" icon={accessibility} className="ion-no-margin" color="primary" />
                  <IonLabel className="ion-text-wrap" style={{ fontSize: '16px' }}>
                    Accessibility
                  </IonLabel>
                </IonItem>
                <IonItem button lines="none" detail={true} onClick={() => setPrivacyModalShow(true)}>
                  <IonIcon slot="start" icon={lockClosed} className="ion-no-margin" color="primary" />
                  <IonLabel className="ion-text-wrap" style={{ fontSize: '16px' }}>
                    Privacy
                  </IonLabel>
                </IonItem>
              </IonList>
            </IonPopover>
            <AccessibilityModal show={AccessibilityModalShow} onHide={() => setAccessibilityModalShow(false)} />
            <PrivacyModal show={PrivacyModalShow} onHide={() => setPrivacyModalShow(false)} />
          </IonList>
          <ManateeFooter />
        </div>
      </IonContent>
    </IonMenu>
  );
};

export default withRouter(Menu);
