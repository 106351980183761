import React, { useRef, useEffect, useContext, useState } from 'react';
// import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { loadModules } from 'esri-loader';
import { IonCard, IonCardContent, NavContext, IonSpinner } from '@ionic/react';
// import * as selectors from "../../data/selectors";

const ArcGisMap = () => {
  let history = useHistory();
  // let location = useLocation();
  const mapEl = useRef(null);
  const { navigate } = useContext(NavContext);
  const [isMapLoading, setIsMapLoading] = useState(true);

  useEffect(() => {
    let view;
    loadModules(['esri/Map', 'esri/WebMap', 'esri/views/MapView', 'esri/widgets/Home', 'esri/config'], {
      css: true,
    }).then(([Map, WebMap, MapView, Home, esriConfig]) => {
      esriConfig.apiKey =
        'AAPKac56007fe9da4af5868d0b5c86e5b678oi9d-MxSKZQiAyqveBbz-ivDfWm4kwxIdvZMSbyNPSbCywUOCFWmhjcz5ojCDwTV';

      const webmap = new WebMap({
        portalItem: {
          id: '88a05ed1d5ba41deaf6eef452a1f77a1',
        },
      });
      const view = new MapView({
        map: webmap,
        container: mapEl.current,
        center: [-82.72, 27.49],
        zoom: 12,
        highlightOptions: {
          color: 'red',
          haloColor: '#0072bc',
          haloOpacity: 0.2,
          fillOpacity: 0,
        },
        constraints: {
          //   geometry: {
          //     type: 'extent',
          //     xmin: -82.904591,
          //     ymin: 27.611128,
          //     xmax: -81.991352,
          //     ymax: 28.326728,
          //   },
          minZoom: 10,
          maxZoom: 18,
        },
        navigation: {
          mouseWheelZoomEnabled: true,
          browserTouchPanEnabled: true,
        },
        popup: {
          defaultPopupTemplateEnabled: false,
          overwriteActions: true,
          visibleElements: {
            closeButton: true,
            featureNavigation: false,
          },
          dockEnabled: true,
          dockOptions: {
            buttonEnabled: false,
            breakpoint: false,
            position: 'bottom-center',
          },
        },
      });

      // Home widget
      const homeBtn = new Home({
        view,
      });

      // Add the home button to the top left corner of the view
      view.ui.add(homeBtn, {
        position: 'top-left',
      });
      view.ui.remove('attribution');

      view.ui.add(homeBtn, {
        position: 'top-right',
      });

      var pointLayer;

      webmap
        .load()
        .then(function () {
          // load the basemap to get its layers created
          return webmap.basemap.load();
        })
        .then(function () {
          // grab all the layers and load them
          const allLayers = webmap.allLayers;
          const promises = allLayers.map(function (layer) {
            return layer.load();
          });
          return Promise.all(promises.toArray());
        })
        .then(function (layers) {
          //   console.log('LAYERS:', layers);
          // each layer load promise resolves with the layer
          //   const polygonLayer = layers[0];
          pointLayer = layers[1];
          //   polygonLayer.listMode = 'hide';
          view.whenLayerView(pointLayer).then(function (layerView) {
            layerView.watch('updating', function (value) {
              // once the layer view finishes updating
              if (!value) {
                setIsMapLoading(false);
                // console.log("done loading");
              }
            });
          });
        });

      history.listen((location) => {
        // console.log(location);
        const query = {
          where: '1=1',
          returnGeometry: true,
          outFields: ['title'],
        };
        pointLayer.queryFeatures(query).then(function (results) {
          if (results) {
            results.features.forEach(function (feature) {
              if ('/projects/' + feature.attributes.title.replace(/\s+/g, '-').toLowerCase() === location.pathname) {
                // console.log('attributes', feature.geometry);
                function catchAbortError(error) {
                  if (error.name !== 'AbortError') {
                    console.error(error);
                  }
                }
                view
                  .goTo(
                    {
                      geometry: feature.geometry,
                      zoom: 14,
                    },
                    {
                      duration: 500,
                    }
                  )
                  .catch(catchAbortError);
              }
            });
          }
        });
      });

      // Zoom to selected feature
      view.popup.watch('selectedFeature', (graphic) => {
        if (graphic) {
          // view.goTo(
          //   { geometry: graphic.geometry, zoom: 16 },
          //   {
          //     duration: 500,
          //   }
          // );
          navigate(`/projects/${graphic.attributes.title.replace(/\s+/g, '-').toLowerCase()}`);
        }
      });
    });

    return () => {
      if (!!view) {
        view.destroy();
        view = null;
      }
    };
    // eslint-disable-next-line
  }, []);
  return (
    <IonCard mode="ios" className="sticky-content">
      <IonCardContent
        style={{
          height: 'calc(100vh - 195px)',
          padding: '0px',
          maxHeight: '1000px',
        }}
      >
        {isMapLoading && (
          <div className="network-error ion-padding ion-text-center">
            <IonSpinner name="lines" color="primary" />
          </div>
        )}
        <div className="webmap" ref={mapEl} />
      </IonCardContent>
    </IonCard>
  );
};

export default ArcGisMap;
