/* eslint-disable no-dupe-keys */
import React, { useEffect, useState } from 'react';
import { IonItem, IonLabel, IonButton } from '@ionic/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from '../data/connect';
import * as selectors from '../data/selectors';
import Modal from 'react-bootstrap/Modal';

const AlertListItem = ({ alert, alertAttachments }) => {
  const [showModal, setShowModal] = useState(false);
  // const [timeSince, setTimeSince] = useState('');

  // useEffect(() => {
  //   const startTime = new Date(alert.startDate);
  //   const endTime = new Date();
  //   const timeDiff = endTime - startTime;
  //   function convertMS(ms) {
  //     let d;
  //     let h;
  //     let m;
  //     let s;
  //     s = Math.floor(ms / 1000);
  //     m = Math.floor(s / 60);
  //     s %= 60;
  //     h = Math.floor(m / 60);
  //     m %= 60;

  //     d = Math.floor(h / 24);
  //     h %= 24;
  //     return { d, h, m, s };
  //   }
  //   const timeSinceIssued = convertMS(timeDiff);
  //   const daysSince = timeSinceIssued.d.toString();
  //   if (daysSince === '0') {
  //     setTimeSince(`Today`);
  //   } else {
  //     setTimeSince(daysSince);
  //   }
  // }, [alert.startDate]);

  const [attachmentUrl, setAttachmentUrl] = useState();
  useEffect(() => {
    const attachmentObject = alertAttachments.find((a) => a.parentObjectId === alert.OBJECTID);
    setAttachmentUrl(attachmentObject.attachmentInfos[0].url);
    // eslint-disable-next-line
  }, []);
  // useEffect(() => {
  //   if (alertAttachments > 0) {
  //     console.log(alertAttachments);
  //     const attachmentObject = alertAttachments.find((a) => a.parentObjectId === alert.OBJECTID);
  //     setAttachmentUrl(attachmentObject.attachmentInfos[0].url);
  //   }
  // }, [alert.OBJECTID, alertAttachments]);

  const getAlertVisibility = (alert) => {
    const currentDate = new Date();
    const startDate = new Date(alert.startDate);
    const endDate = new Date(alert.endDate);
    if (startDate < currentDate && endDate > currentDate) {
      return false;
    } else {
      return true;
    }
  };

  function createParagraphs(value) {
    return {
      __html: (value || '').replace(/:break/g, '<br /><br />'),
    };
  }

  // const dateFormatter = (timestamp) => {
  //   var date = new Date(timestamp);
  //   var month = [];
  //   month[0] = 'January';
  //   month[1] = 'February';
  //   month[2] = 'March';
  //   month[3] = 'April';
  //   month[4] = 'May';
  //   month[5] = 'June';
  //   month[6] = 'July';
  //   month[7] = 'August';
  //   month[8] = 'September';
  //   month[9] = 'October';
  //   month[10] = 'November';
  //   month[11] = 'December';
  //   var m = month[date.getMonth()];
  //   var d = date.getDate();
  //   var hr = date.getHours();
  //   var min = date.getMinutes();
  //   if (min < 10) {
  //     min = '0' + min;
  //   }
  //   var ampm = 'am';
  //   if (hr > 12) {
  //     hr -= 12;
  //     ampm = 'pm';
  //   }
  //   return m + ` ` + d + ` at ` + hr + ':' + min + ampm;
  // };

  return (
    <>
      <IonItem
        button
        lines="none"
        target="_blank"
        // key={alert.OBJECTID}
        className="alert-list-item"
        hidden={getAlertVisibility(alert)}
        onClick={() => setShowModal(true)}
      >
        <div slot="start" style={{ marginTop: '10px' }}>
          <FontAwesomeIcon
            icon={['fas', `${alert.icon}`]}
            color={`var(--ion-color-${alert.color})`}
            style={{ fontSize: '26px', minWidth: '24px' }}
          />
        </div>
        <IonLabel className="alert-label">
          <div
            style={{
              display: '-webkit-flex',
              display: 'flex',
              WebkitJustifyContent: 'space-between',
              justifyContent: 'space-between',
              WebkitAlignItems: 'center',
              alignItems: 'center',
            }}
          >
            <h3
              style={{
                display: 'inline-block',
                fontSize: '18px',
                fontWeight: '600',
              }}
            >
              {alert.title}
            </h3>
            {/* <span
              className="date"
              style={{
                display: 'inline-block',
                fontSize: '12px',
                color: 'rgba(0, 0, 0, 0.45)',
                position: 'absolute',
                top: '15px',
                right: '15px',
              }}
            >
              {timeSince}
            </span> */}
          </div>
          <p>{alert.description}</p>
        </IonLabel>
      </IonItem>
      <Modal
        size="lg"
        dialogClassName="modal-component"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        className="cip-modal"
        show={showModal}
        onHide={() => setShowModal(false)}
        restoreFocus={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <FontAwesomeIcon
              icon={['fas', `${alert.icon}`]}
              color={`var(--ion-color-${alert.color})`}
              style={{ fontSize: '26px', minWidth: '24px', marginRight: '10px', marginLeft: '5px' }}
            />

            {alert.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {/* <p>
              <span style={{ fontWeight: '600', color: '#000' }}>Issued:</span>{' '}
              {alert.startDate ? dateFormatter(alert.startDate) : 'N/A'} <br />
              <span style={{ fontWeight: '600', color: '#000' }}>Expires: </span>
              {alert.endDate ? dateFormatter(alert.endDate) : 'N/A'}
            </p> */}
            <p style={{ marginBottom: '20px' }} dangerouslySetInnerHTML={createParagraphs(alert.description)}></p>
            <IonButton
              color="secondary"
              fill="solid"
              size="default"
              download
              href={attachmentUrl}
              target="_blank"
              style={{ marginBottom: '15px' }}
            >
              <FontAwesomeIcon icon={['far', 'file-pdf']} style={{ fontSize: '20px', marginRight: '7px' }} />
              View Full Alert Details
            </IonButton>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    alertAttachments: selectors.getAlertAttachments(state),
  }),
  component: React.memo(AlertListItem),
});
