import React, { useRef, useEffect, useState } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonList,
} from '@ionic/react';
import { connect } from '../data/connect';
import { AnimatePresence, motion } from 'framer-motion';
import ProjectListPopover from '../components/ProjectListPopover';
import AlertListPopover from '../components/AlertListPopover';
import ProjectDetail from '../components/ProjectDetail';
import ProjectList from '../components/ProjectList';
import ArcGisMap from '../components/ArcGisMap';

const Projects = ({ projects }) => {
  const routerRef = useRef(null);

  // Keyboard Focus (Accessibility) - Set focus to top of project list on first page enter to Projects. Set keyboard focus to main content on project selection
  const location = useLocation();
  const [pathname, setPathname] = useState();
  const prevPathnameRef = useRef(null);
  const projectListTitle = useRef(null);
  const projectTitle = useRef(null);
  useEffect(() => {
    prevPathnameRef.current = pathname;
  });
  const prevPathname = prevPathnameRef.current;
  useEffect(() => {
    setPathname(location.pathname);
  }, [location.pathname]);
  useEffect(() => {
    const focus = setTimeout(() => {
      if (prevPathname == null) {
        projectListTitle.current.focus();
      } else if (prevPathname != null && !prevPathname.includes('projects')) {
        projectListTitle.current.focus();
      } else {
        projectTitle.current.focus();
      }
    }, 1);
    return () => clearTimeout(focus);
  }, [pathname, prevPathname]);
  // End Keyboard Focus (Accessibility)

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>AMI Projects</IonTitle>
          <AlertListPopover />
        </IonToolbar>
      </IonHeader>
      <IonContent className="background">
        <IonGrid>
          <IonRow>
            {/* Project List */}
            <IonCol sizeXl="2.5" sizeLg="2.5" sizeMd="3.5" sizeSm="4.5" sizeXs="12" className="project-list-responsive">
              <IonCard mode="ios" className="project-list sticky-content">
                <IonCardHeader>
                  <IonCardTitle className="project-list">
                    <span className="focused-title" tabIndex="-1" ref={projectListTitle}>
                      Projects
                    </span>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonList className="project-list">
                    <ProjectList />
                  </IonList>
                </IonCardContent>
              </IonCard>
            </IonCol>
            {/* Main Content - Project Details */}
            <IonCol sizeXl="5.5" sizeLg="5.5" sizeMd="6" sizeSm="12" sizeXs="12" className="ion-text-center">
              <ProjectListPopover /> {/* Hidden when screen size is bigger than Medium */}
              <IonCard mode="ios" className="project-detail ion-text-left">
                <span className="focused-title" tabIndex="-1" ref={projectTitle}></span>
                {/* Fade animation when when main content changes to selected project */}
                <AnimatePresence exitBeforeEnter>
                  {/* Switch that renders project details from the project data stored in global state */}
                  <Switch ref={routerRef}>
                    {projects.map((route, index) => (
                      <Route
                        key={index}
                        path={`/projects/${route.attributes.slug}`}
                        exact
                        render={() => (
                          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                            {<ProjectDetail projectSlug={route.attributes.slug} router={routerRef.current} />}
                          </motion.div>
                        )}
                      />
                    ))}
                    <Redirect from="/projects" to="/projects/bradenton-beach-sewer-improvements" />
                  </Switch>
                </AnimatePresence>
              </IonCard>
            </IonCol>
            {/* Sidebar Sontent - ArcGIS Map */}
            <IonCol sizeXl="4" sizeLg="4" sizeMd="6" sizeSm="12" sizeXs="12">
              <ArcGisMap />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    projects: state.data.projects,
  }),
  component: Projects,
});
