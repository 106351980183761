import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  IonButton,
  IonIcon,
  IonCol,
  IonGrid,
  IonRow,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonSpinner,
} from "@ionic/react";
import { checkmarkCircle } from "ionicons/icons";
import ReactOneSignal from "react-onesignal";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

let initialValues = {
  email: "",
};

const AlertSettingsModal = (props) => {
  const { show, onHide } = props;
  const [isSubscribed, setIsSubscribed] = useState();
  const [showLoading, setShowLoading] = useState(false);

  const onModalEnter = () => {
    ReactOneSignal.getEmailId().then(function (emailId) {
      if (emailId === undefined) {
        setIsSubscribed(false);
      } else {
        setIsSubscribed(true);
      }
    });
  };
  const { control, handleSubmit, errors } = useForm({
    defaultValues: { ...initialValues },
    mode: "onChange",
  });

  const onEmailSubscribe = (data) => {
    setShowLoading(true);
    ReactOneSignal.setEmail(data.email)
      .then(function (emailId) {
        if (emailId) {
          setTimeout(() => {
            setShowLoading(false);
            setIsSubscribed(true);
          }, 500);
          setTimeout(() => {
            onHide();
          }, 1500);
        }
      })
      .catch(() => {
        setShowLoading(false);
      });
  };
  const handleUnsubscribe = () => {
    setShowLoading(true);
    ReactOneSignal.getEmailId()
      .then(function (emailId) {
        if (emailId === undefined) {
          return;
        } else {
          ReactOneSignal.logoutEmail();
          setTimeout(() => {
            setShowLoading(false);
            setIsSubscribed(false);
          }, 1000);
        }
      })
      .catch(() => {
        setShowLoading(false);
      });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={true}
      id="alert-setting-modal"
      onEnter={onModalEnter}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {isSubscribed ? "Manage Email Alerts" : "Subscribe to Email Alerts"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="ion-padding-bottom">
        {!isSubscribed && (
          <form
            onSubmit={handleSubmit(onEmailSubscribe)}
            id="emailAlertsSubscribe"
          >
            <IonGrid className="ion-no-padding">
              <IonList className="project-list">
                <IonItem mode="md" disabled={showLoading}>
                  <IonLabel position="floating">Email Address</IonLabel>
                  <Controller
                    render={({ onChange, onBlur, value }) => (
                      <IonInput onIonChange={onChange} />
                    )}
                    control={control}
                    name="email"
                    rules={{
                      required: "This is field is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Enter a valid email address",
                      },
                    }}
                  />
                </IonItem>
                <ErrorMessage
                  errors={errors}
                  name="email"
                  as={
                    <div
                      style={{
                        color: "var(--ion-color-danger)",
                        marginBottom: "5px",
                        textTransform: "uppercase",
                        fontWeight: "600",
                        fontSize: "12px",
                        marginLeft: "13px",
                      }}
                    />
                  }
                />
              </IonList>

              <IonRow>
                <IonCol className="ion-text-start">
                  <IonButton
                    fill="outline"
                    color="danger"
                    onClick={props.onHide}
                  >
                    Cancel
                  </IonButton>
                </IonCol>
                <IonCol className="ion-text-end">
                  <IonButton type="submit">
                    Subscribe
                    {showLoading && (
                      <IonSpinner
                        duration={1000}
                        name="crescent"
                        style={{ marginLeft: "10px" }}
                      />
                    )}
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        )}
        {isSubscribed && (
          <IonGrid className="ion-no-padding">
            <IonRow>
              <IonList className="project-list" style={{ width: "100%" }}>
                <IonItem
                  mode="md"
                  lines="none"
                  id="alert-button"
                  disabled={showLoading}
                >
                  <IonIcon
                    ios={checkmarkCircle}
                    slot="start"
                    size="large"
                    color="success"
                    className="alert-icon"
                  />
                  <IonLabel>
                    <h2>You're subscribed to email alerts</h2>
                  </IonLabel>
                </IonItem>
              </IonList>
            </IonRow>
            <IonRow className="ion-margin-top-half">
              <IonCol className="ion-text-start">
                <IonButton fill="outline" color="danger" onClick={props.onHide}>
                  Cancel
                </IonButton>
              </IonCol>
              <IonCol className="ion-text-end">
                <IonButton color="danger" onClick={handleUnsubscribe}>
                  Unsubscribe
                  {showLoading && (
                    <IonSpinner
                      duration={1000}
                      name="crescent"
                      style={{ marginLeft: "10px" }}
                    />
                  )}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AlertSettingsModal;
